import type { UIBreadcrumbsItem } from '@design-system/components/UI/UIBreadcrumbs.props'

export const useCustomBreadcrumbsSchema = (
  data: UIBreadcrumbsItem[] | undefined
) => {
  if (!data || !data.length) return
  const siteResolver = createSitePathResolver({
    canonical: true,
    absolute: true,
  })

  useSchemaOrg([
    defineBreadcrumb({
      itemListElement: data.map(({ label, url }) => ({
        name: label,
        ...(url ? { item: siteResolver(url).value } : {}),
      })),
    }),
  ])
}

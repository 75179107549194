import type { ProductSchema } from 'integration-layer/.nuxt/armani/product-schema'
import { cleanDoubleSlashes } from 'ufo'

type Breadcrumb = {
  label: string
  url: string
}

type AmpliencePageData = {
  categoryTitle: string
  urlSlug: string
  parentPage?: AmpliencePageData | null
  brand?: string
} | null

type WithPageTitle<T> = T & { currentPageTitle: string }
type AlgoliaProductCategories = ProductSchema['categoryPageId']
type AlgoliaRequiredParams = WithPageTitle<{
  dataOrigin: 'algolia'
  productCategories: AlgoliaProductCategories
}>

type AmplienceRequiredParams = WithPageTitle<{
  dataOrigin: 'amplience'
  pageData: AmpliencePageData
}>

type UseBreadcrumbsParams = AmplienceRequiredParams | AlgoliaRequiredParams

export const useBreadcrumbs = (params: UseBreadcrumbsParams) => {
  const { ts, getLocale } = useI18n()
  const locale = getLocale()

  const mapBreadcrumb = (
    name: string | undefined,
    path: string | undefined,
    currentIndex: number,
    nCategories: number
  ): Breadcrumb => {
    const isLastItem = currentIndex === nCategories - 1
    return {
      label: name ?? '',
      url: !isLastItem ? (cleanDoubleSlashes(path) ?? '') : '',
    }
  }

  const buildBreadcrumbsFromAlgoliaProductCategories = ({
    currentPageTitle,
    productCategories,
  }: WithPageTitle<AlgoliaRequiredParams>) => {
    const categories = [
      {
        name: ts('breadcrumbs.home'),
        slug: locale,
      },
      ...productCategories,
      {
        name: currentPageTitle,
      },
    ]
    return categories.reduce<Breadcrumb[]>((acc, category, currentIndex) => {
      if (!category?.name) return acc
      const previousPath = acc[currentIndex - 1]?.url ?? ''
      const breadcrumb = mapBreadcrumb(
        category.name,
        `/${previousPath}/${category.slug}`,
        currentIndex,
        categories.length
      )
      acc.push(breadcrumb)
      return acc
    }, [])
  }

  const buildBreadcrumbsFromAmpliencePage = ({
    currentPageTitle,
    pageData,
  }: WithPageTitle<AmplienceRequiredParams>) => {
    const buildAmpliencePageSlug = (
      pages: AmpliencePageData[],
      currentPageIndex: number
    ) =>
      pages
        .slice(0, currentPageIndex + 1)
        .reduce((acc, page) => (acc += `${page?.urlSlug ?? ''}/`), '')

    const pages: AmpliencePageData[] = []

    const getPages = (page?: AmpliencePageData) => {
      if (!page || !Object.keys(page).length || !page.urlSlug) return
      pages.unshift(page)
      getPages(page.parentPage)
    }

    getPages(pageData)
    pages.unshift({
      categoryTitle: ts('breadcrumbs.home'),
      urlSlug: `/${locale}`,
      parentPage: pageData,
    })

    pages.push({
      categoryTitle: currentPageTitle,
      urlSlug: '',
      parentPage: null,
    })

    return pages.map<Breadcrumb>((page, currentPageIndex) =>
      mapBreadcrumb(
        page?.categoryTitle,
        buildAmpliencePageSlug(pages, currentPageIndex),
        currentPageIndex,
        pages.length
      )
    )
  }

  const breadcrumbs = computed(() => {
    if (params.dataOrigin === 'algolia' && params.productCategories?.length) {
      return buildBreadcrumbsFromAlgoliaProductCategories(params)
    }
    if (params.dataOrigin === 'amplience') {
      return buildBreadcrumbsFromAmpliencePage(params) ?? []
    }
    return []
  })

  useCustomBreadcrumbsSchema(breadcrumbs.value)
  return { breadcrumbs }
}

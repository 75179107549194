<script setup lang="ts">
import type { EditorialModulesWrapperProps } from './EditorialModulesWrapper.props'

const props = defineProps<EditorialModulesWrapperProps>()

const localePath = useLocalePath()

const routeParams = useRoute().params
// @ts-ignore
const slug = routeParams.slug

const query = {
  locale: localePath(''),
  vse: useRoute().query.vse as string,
  contentId: useRoute().query.contentId,
  slug,
}
const { data: componentResponse, execute } = await useFetch<any>(
  `/api/cms/getByKey?locale=${query.locale}&id=${props.deliveryKey}&type=key&vse=${query?.vse ?? ''}`,
  {
    getCachedData: (k, nuxtApp) => nuxtApp.payload.data[k],
    immediate: false,
  }
)
if (!componentResponse.value) {
  await execute()
}
const editorials = computed(() =>
  connectComponents(
    componentResponse.value ? [componentResponse.value] : [],
    query.locale,
    query.vse ?? ''
  )
)
</script>

<template>
  <AmplienceDynamicContent :components="editorials" />
</template>

export const scrollIntoPageView = async (options?: {
  scrollBothStrips: boolean
  dynamicScrollPlp: boolean
}) => {
  const headerMonogram = document.getElementById('header-monogram')
  const scrollYVal = localStorage.getItem('scrollYVal')
    ? JSON.parse(localStorage.getItem('scrollYVal') || '')
    : 0

  if (headerMonogram) {
    nextTick(() => {
      let headerHeight = headerMonogram?.clientHeight

      const stripsContainer = document.getElementById('strips-container')
      if (stripsContainer && options?.scrollBothStrips) {
        headerHeight = stripsContainer?.clientHeight
      }

      //TODO: (tracked #1217) gotta find a better way to handle the double requestAnimationFrame
      // https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (
            scrollYVal?.scroll &&
            options?.dynamicScrollPlp &&
            scrollYVal?.prodPage
          ) {
            const productTileId = `${scrollYVal?.scroll}`
            let element = document.getElementById(productTileId)
            if (element) {
              nextTick(() => {
                element = document.getElementById(productTileId)
                if (element) {
                  element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  })
                }
              })
            } else {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }
          } else {
            window.scrollTo({
              top: headerHeight,
              behavior: 'smooth',
            })
          }
        })
      })
    })
  }
}
